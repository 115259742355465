.skillcard {
  background-color: var(--bg_component_color);
  border-bottom: 2px solid transparent;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  padding: 38px 42px;
  transition: all 0.15s ease-in-out;
}

.skillcard:hover {
  border-bottom-color: var(--border_color);
}

.skillcard-title {
  color: var(--content_title_color);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.logo-card {
  height: 80px;
  object-fit: contain;
  max-width: 120px;
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
}

.logo-card:hover {
  opacity: 1;
}

.skillcard-item {
  transition: all 0.2s ease-in-out;
}

.skillcard-item:hover {
  cursor: pointer;
  transform: scale(1.2);
  color: white;
}

.skillcard-header-container {
  padding-bottom: 16px;
}

.logo-card-caption {
  padding-top: 15px;
}

.skillcard-item {
  padding-top: 14px;
}
