.card {
  background-color: var(--bg_component_color);
  border-bottom: 2px solid transparent;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  transition: all 0.15s ease-in-out;
}

.card:hover {
  border-bottom-color: var(--border_color);
}

.card-text {
  color: var(--text_color);
  font-size: 14px;
}

.card-footer {
  padding-bottom: 20px;
}

.card-image {
  width: 100%;
}

.card-footer .hide-buttons {
  display: none;
}

.btn-custom {
  color: #4bffa5;
  border-color: #4bffa5;
}

.btn-custom:hover {
  background-color: #4bffa5 !important;
  color: #161616 !important;
}

.btn-custom:focus {
  color: #4bffa5 !important;
  border-color: #4bffa5 !important;
}

.btn-custom:active {
  background-color: #4bffa5 !important;
  color: #161616 !important;
}
