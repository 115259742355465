.margin-content {
  margin: 70px 0;
}

.content-title {
  line-height: 1.5;
  margin-bottom: 12px;
}

.info-description {
  font-size: 15px;
  margin-bottom: 28px;
  color: var(--subtitle_color);
}

.list-info > li:first-child .info-icon {
  margin-bottom: 13px;
}

.info-icon {
  font-size: 36px;
  margin-right: 50px;
  position: relative;
}

.info-icon i {
  color: var(--icon_color);
  font-size: 36px;
  left: 0;
  line-height: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.list-info > li:first-child .info-details {
  padding-top: 0;
}

.info-details {
  border-left: 2px solid var(--divider_border_color);
  padding: 12px 0 12px 22px;
}

.info-type {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}

.info-value {
  color: var(--text_color);
}

.info-value a {
  color: var(--highlight_color);
  text-decoration-line: none;
}

.info-value a:hover {
  color: var(--highlight_hover_color);
}

.contact-info {
  flex-wrap: wrap;
}

.icon-class:hover {
  color: var(--icon_hover_color);
  cursor: pointer;
}
