.resume {
  margin-top: 70px;
}

.col-section {
  margin-right: 20px;
}

.col-sec-title {
  font-size: 26px;
  margin-bottom: 20px;
}

.resume-sec {
  padding-bottom: 35px;
}

.resume-sec-item {
  border-left: 2px solid transparent;
  border-left-color: var(--border_color);
  background-color: var(--bg_component_color);
  padding: 32px 48px;
  position: relative;
}

.resume-sec-item:not(:last-child) {
  border-bottom: 1px solid;
  border-bottom-color: var(--divider_bottom_color);
}

.sec-item-arrow::before {
  height: 16px;
  left: 0;
  width: 16px;
  background-color: var(--border_color);
  content: "";
  display: block;
  position: absolute;
}

.sec-item-arrow::after {
  border: 8px solid transparent;
  border-left-color: var(--border_color);
  left: 16px;
  content: "";
  display: block;
  position: absolute;
}

.skill-sec {
  padding-top: 35px;
}

.sec-item-title {
  line-height: 100%;
  margin-bottom: 9px;
}

.sec-item-details {
  display: inline-block;
  margin-bottom: 7px;
}

.sec-item-description {
  font-size: 14px;
  white-space: pre-wrap;
}

.btn-div {
  display: flex;
  justify-content: center;
}

.btn-custom {
  color: #4bffa5;
  border-color: #4bffa5;
}

.btn-custom:hover {
  background-color: #4bffa5;
  color: #161616;
}
