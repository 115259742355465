:root {
  --navbar_color: #191919;
  --navbar_item_color: #e6e6e6;
  --home_name_color: #fff;
  --home_desc_color: #fff;
  --subtitle_color: #9f9f9f;
  --text_color: #9f9f9f;
  --title_color: #f0f0f0;
  --content_title_color: #e6e6e6;
  --bg_color: #101010;
  --bg_component_color: #161616;
  --icon_color: #f0f0f0;
  --icon_hover_color: #4bffa5;
  --highlight_color: #4bffa5;
  --highlight_hover_color: #9f9f9f;
  --border_color: #4bffa5;
  --divider_border_color: #313131;
  --selection_color: #009e66;
  --button_default: #4bffa5;
  --button_hover: #04da8f;
}

.app {
  background: var(--bg_color);
  color: var(--text_color);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title_color);
}

.heading-container {
  margin-bottom: 40px;
  text-align: center;
}

.heading-title {
  font-size: 42px;
  color: var(--title_color);
  font-weight: 700;
}

.heading-subtitle {
  font-size: 15px;
  color: var(--subtitle_color);
  margin-bottom: 17px;
}

.main-section {
  padding-top: 35px;
  padding-bottom: 35px;
}

.main-section-content {
  padding-top: 40px;
}

::-moz-selection {
  background-color: var(--selection_color);
  color: #fff;
}
::selection {
  background-color: var(--selection_color);
  color: #fff;
}

.social-icon-list li a {
  color: var(--icon_color);
  font-size: x-large;
  margin-right: 20px;
}

.social-icon-list li a:hover {
  color: var(--icon_hover_color);
}

@media (max-width: 320px) {
  .app {
    width: fit-content;
  }
}
