.project-nav {
  text-align: center;
  margin-bottom: 30px;
}
.project-nav .project-nav-item {
  cursor: pointer;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  position: relative;
  transition: all 0.1s ease-in-out;
  margin-right: 20px;
}

.project-nav-item:hover {
  color: var(--border_color);
}

.highlight::before {
  bottom: 0;
  content: "";
  background-color: var(--border_color);
  height: 1px;
  position: absolute;
  width: 100%;
}

.highlight {
  color: var(--border_color);
}
