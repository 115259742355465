.logoContainer {
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.logo {
  object-fit: contain;
  background-size: cover;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.logo:hover {
  transform: scale(1.1);
}

.nav-logo {
  cursor: pointer;
}

.navbar-color-transparent {
  background-color: transparent;
  padding: 10px 50px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar-color-dark {
  background-color: var(--navbar_color);
  padding: 10px 50px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.myNavList .nav-item .nav-link {
  cursor: pointer;
  color: var(--navbar_item_color);
  font-size: 15px;
}

.myNavList .nav-item:not(:last-child) .nav-link {
  margin-right: 32px;
}

.myNavList .nav-item .nav-link:hover {
  color: var(--icon_hover_color);
}

.navbar-menu {
  display: none;
}

.nav-item > .active {
  color: var(--border_color) !important;
  border-bottom: 1px solid var(--border_color);
}

#submit-button-default {
  background-color: var(--button_default) !important;
  color: #101010;
  border: 1px solid var(--button_default) !important;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#submit-button-default > span {
  margin-right: 5px;
  color: #101010;
}

#submit-button-default:hover {
  background-color: var(--selection_color) !important;
  color: #101010;
  border: 1px solid var(--selection_color) !important;
}

.hire-button-a {
  text-decoration: none;
  color: #101010;
}

.hire-button-a:hover {
  text-decoration: none;
  color: #101010;
}

@media (max-width: 767.95px) {
  .myNavList {
    display: block;
    background-color: var(--navbar_color);
    padding: 8px 20px;
    position: relative;
    top: 8px;
    width: 200px;
    height: auto;
    z-index: 2;
  }

  .navbar {
    padding: 0;
  }

  .nav-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--navbar_color);
    width: 100%;
    padding: 10px 50px;
  }

  .navbar-color-dark {
    background-color: transparent;
  }

  .navbar-menu {
    -ms-flex-direction: column;
    -ms-flex-pack: justify;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    height: 19px;
    justify-content: space-between;
    position: relative;
    width: 30px;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(240,240,240, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }

  .navbar-menu:before {
    content: "";
    width: 85%;
  }

  .nav-cont button:hover {
    outline: 5px auto var(--navbar_item_color);
  }

  .navbar .navbar-menu:after {
    content: "";
  }

  .myNavList .nav-item .nav-link {
    text-align: center;
    margin-right: 0 !important;
  }
  .hire-button-a {
    display: flex;
    justify-content: center;
  }
}
